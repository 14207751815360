export default function signIn(parentWindow, params) {
    return new Promise( resolve => {
        let DIDURL = 'https://did.gavrint.com?' + params;

        let width = 360;
        let height = 500;
        const popupParams = `modal=yes, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=${width}, height=${height}`;

        let loginWindow = parentWindow.open(DIDURL, 'Web3DID', popupParams);
        if (loginWindow == null || loginWindow == undefined) {
            resolve({result:'BLOCKED', message:'DID popup blocked by browser'});
        }

        var timer = setInterval( () => {
            loginWindow.postMessage({
                didhandshake: 'true'
            }, 'https://did.gavrint.com');

            if ( parentWindow.handshakeDID == true ) {
                clearInterval(timer);
                resolve({result:'OK', message:'start DID signin'});
            }

            if (loginWindow.closed) {
                console.log('DID canceled');
                clearInterval(timer);
                resolve({result:'CANCEL', message:'canceled DID signin'});
            }
        }, 1000);          
    });
}